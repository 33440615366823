.slugData {
  display: flex;
  align-items: center;
}

.modelData {
  padding: 25px 30px;
  border: 2px solid #887258;
}

.slugData img {
  height: 50px;
  width: 100px;
  margin: 0 20px 8px 0
}

.slugName {
  margin-top: 10px;
  font-weight: bold;
}

.slugLine {
  border-top: 2px solid #ccc;
  margin: 10px 0 10px 0;
  width: 44rem;
}


.title {
  font-weight: bold;
  font-size: 17px;
  color: #F6931E;
  margin-top: 3px;
}

.details {
  color: #707070;
  font-size: 14px;
  position: relative;
}

.lineDetails {
  display: flex;
  align-items: center;
}

.details::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  height: .5px;
  width: 100%;
  background-color: #707070;
  margin-left: 8px;
}

.details::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  padding-right: 10px;
  white-space: nowrap;
  color: #707070;
}

.error-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 1.9rem;
  z-index: 1;
  align-items: flex-end;
  top: -2.5rem;
}

.error-msg-container {
  padding: .5rem 1rem;
  /* background: linear-gradient(to left, #ED4168, #FFB25A); */
  background-color: #F6931E;
  border-radius: .6rem;
  color: #fff;
  font-weight: 900;
}

.errArrow {
  width: 0;
  height: 0;
  border-top: 10px solid #F6931E;
  border-left: 10px solid transparent;
  margin-right: .5rem;
}

.error-icon {
  width: 20px;
  margin-top: .4rem;
}


/* new changes */

.changes {
  background-color: #F6931E;
  padding: .6rem 1rem;
  width: auto;
  border: none;
  border-radius: 1.8rem;
  font-size: 1rem;
  cursor: pointer;
  /* margin: auto; */
  margin-top: 1rem;
}

.changes:disabled {
  background-color: #cccccc;
  /* Greyed-out background for disabled */
  color: #666666;
  /* Faded text color for disabled */
  cursor: not-allowed;
  /* Show not-allowed cursor */
  opacity: 0.7;
  /* Slightly transparent */
}

.changes:hover:disabled {
  /* Optional: Ensure hover effects are overridden for disabled buttons */
  background-color: #cccccc;
  color: #666666;
}

.main-action {
  display: flex;
  justify-content: space-between !important;
}


/* Mobile */
.slugLine-mobile {
  border-top: 2px solid #ccc;
  margin: 10px 0 10px 0;
  width: 18rem;
}